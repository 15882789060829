import React from 'react';
import { pageData } from 'shared/types';
import { Location } from 'history';

interface Props {
  currentPage: pageData;
  location: Location;
}

export const PageData: React.FC<Props> = ({
  currentPage,
  location,
}: Props): JSX.Element => {
  const show: boolean = process.env.REACT_APP_DEBUG === '1';
  return (
    <>
      {show && (
        <div>
          <pre>
            Location:
            <br />
            name: {location.pathname}
            <br />
            <br />
            Current Page:
            <br />
            name: {currentPage.name}
            <br />
            path: {currentPage.urlPath}
            <br />
            path: {currentPage.componentPath}
            <br />
          </pre>
        </div>
      )}
    </>
  );
};
