import React from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { pageData } from 'shared/types';
import { Navigation } from 'shared/components';
import { HOME } from 'shared/consts';
import logo from 'shared/assets/images/logo.svg';

interface Props {
  links?: pageData[];
}

export const TopNavigation: React.FC<Props> = ({
  links,
}: Props): JSX.Element => {
  return (
    <Navbar
      className="bg-light border-bottom margin-bottom-1em"
      variant="light"
      expand="lg"
    >
      <Navbar.Brand>
        <Link to={HOME.urlPath}>
          <img src={logo} alt="Miami Rehab, LLC" height="76px" width="100%" />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Navigation links={links} />
      </Navbar.Collapse>
    </Navbar>
  );
};
