import React, { useEffect, useState } from 'react';
import { Button, Fade } from 'react-bootstrap';
import { getTop, scrollToTop } from 'shared/utils';
import 'shared/assets/styles/scroll-to-top-button.scss';

interface Props {
  /**
   * Optional space separated classes to attach to the button.
   * Defaults to 'to_stt-btn'
   */
  classNames?: string;
  /**
   * Optional button text to display.
   * Defaults to 'Top'
   */
  text?: string;
  /**
   * Optional onClick handler fired when the button is clicked.
   * Defaults to scrollToTop.
   */
  onClickHandler?: () => void;
  /**
   * Optional vertical scroll before the button appears.
   * Defaults to 0.
   */
  showAt?: number;
}

export const ScrollToTopButton: React.FC<Props> = ({
  classNames,
  text = 'Top',
  onClickHandler = scrollToTop,
  showAt = 10,
}: Props): JSX.Element | null => {
  const [top, setTop] = useState<number>(0);
  const handleScroll = (): void => setTop(getTop);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return (): void => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <Fade
      in={top > showAt}
      unmountOnExit={true}
      mountOnEnter={true}
      timeout={500}
    >
      <Button className={`${classNames} to_stt-btn`} onClick={onClickHandler}>
        {text}
      </Button>
    </Fade>
  );
};
