import { pageData } from 'shared/types';

export const HOME: pageData = {
  name: 'Home',
  urlPath: '/',
  componentPath: 'screens/pages/home/Home',
};

export const SERVICES: pageData = {
  name: 'Services',
  urlPath: '/services',
  componentPath: 'screens/pages/services/Services',
};

export const HELLO_WORLD: pageData = {
  name: 'Hello World',
  urlPath: '/hello-world',
  componentPath: 'screens/pages/hello-world/HelloWorld',
};

export const WHY_US: pageData = {
  name: 'Why Us',
  urlPath: '/why-us',
  componentPath: 'screens/pages/why-us/WhyUs',
};

export const CONTACT_US: pageData = {
  name: 'Contact Us',
  urlPath: '/contact-us',
  componentPath: 'screens/pages/contact-us/ContactUs',
};

export const ERROR_404: pageData = {
  name: 'Error 404',
  urlPath: '',
  componentPath: 'screens/pages/error-404/Error404',
};
