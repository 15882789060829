export const scrollToTop = (): void => {
  window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
};

export function getLeft(): number {
  const doc = document.documentElement;
  return (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
}

export function getTop(): number {
  const doc = document.documentElement;
  return (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
}
