import React, { Suspense } from 'react';
import { ERROR_404 } from 'shared/consts';
import { pageData } from 'shared/types';
import { PageComponentLoading } from 'shared/components';

interface Props {
  page: pageData | undefined;
}

export const PageLoader: React.FC<Props> = ({ page }: Props): JSX.Element => {
  const pageToLoad = page ? page : ERROR_404;
  /*
   * The component path should be relative to this component and needs to be
   * understood by Webpack.
   */
  const PageComponent = React.lazy(() =>
    import(`../../../${pageToLoad.componentPath}`),
  );
  return (
    <Suspense fallback={PageComponentLoading(pageToLoad.name)}>
      <PageComponent />
    </Suspense>
  );
};
