import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Route, useLocation } from 'react-router-dom';
import { Location } from 'history';
import { ERROR_404, TOP_NAV_LINKS } from 'shared/consts';
import { pageData } from 'shared/types';
import { getPageLinkFromUrl } from 'shared/utils';
import {
  Footer,
  PageData,
  PageLoader,
  ScrollToTopButton,
  TopNavigation,
} from 'shared/components';
import 'shared/assets/styles/index.scss';

const App: React.FC = (): JSX.Element => {
  const location: Location = useLocation();
  const pageFromUrl: pageData | undefined = getPageLinkFromUrl();
  const currentPage: pageData = pageFromUrl ? pageFromUrl : ERROR_404;

  return (
    <>
      <TopNavigation links={TOP_NAV_LINKS} />
      <PageData currentPage={currentPage} location={location} />
      <Container className="content">
        <Row>
          <Col>
            <Route path="/:pagePath?">
              <PageLoader page={currentPage} />
            </Route>
          </Col>
        </Row>
      </Container>
      <Footer />
      <ScrollToTopButton classNames="btn-light border" />
    </>
  );
};

export default App;
