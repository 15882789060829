import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export const PageComponentLoading: React.FC<string> = (
  pageName: string,
): JSX.Element => {
  return (
    <Container>
      <Row>
        <Col>
          <p>Loading {pageName} page...</p>
        </Col>
      </Row>
    </Container>
  );
};
