import React from 'react';
import { Nav } from 'react-bootstrap';
import { pageData } from 'shared/types';
import { LinkContainer } from 'react-router-bootstrap';

interface Props {
  links?: pageData[];
}

export const Navigation: React.FC<Props> = ({ links }: Props): JSX.Element => {
  return (
    <Nav className="mr-auto">
      {links &&
        links.map((link: pageData, index: number) => {
          return (
            <LinkContainer key={`linkCont${index}`} to={link.urlPath}>
              <Nav.Link key={'topNav' + index}>{link.name}</Nav.Link>
            </LinkContainer>
          );
        })}
    </Nav>
  );
};
