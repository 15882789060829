import { pageData } from 'shared/types';
import { CONTACT_US, HELLO_WORLD, HOME, SERVICES, WHY_US } from './site-pages';

export const ALL_NAV_LINKS: pageData[] = [
  HOME,
  HELLO_WORLD,
  SERVICES,
  WHY_US,
  CONTACT_US,
];

export const TOP_NAV_LINKS: pageData[] = [SERVICES, WHY_US, CONTACT_US];

export const BOTTOM_NAV_LINKS: pageData[] = [
  HOME,
  SERVICES,
  WHY_US,
  CONTACT_US,
];
