import { pageData } from '../types';
import { ALL_NAV_LINKS, HOME } from '../consts';

export const getPageLinkFromUrl = (): pageData | undefined => {
  const pagePath: string = window.location.pathname;
  if ('' === pagePath) {
    return HOME;
  }
  return ALL_NAV_LINKS.find((link: pageData): boolean => {
    const result: boolean = link.urlPath === pagePath;
    return result;
  });
};
