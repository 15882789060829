import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { currentYear } from 'shared/utils';
import { Navigation } from 'shared/components';
import { pageData } from 'shared/types';

interface Props {
  links?: pageData[];
}

export const Footer: React.FC<Props> = ({ links }: Props): JSX.Element => {
  return (
    <Container className="footer">
      <Row style={{ padding: '1em 0 0.4em 0' }}>
        <Col>
          <Navigation links={links} />
          <small>©2012 - {currentYear()} Miami Rehab, LLC</small>
        </Col>
      </Row>
    </Container>
  );
};
